
































































import mixins from 'vue-typed-mixins'
import {
  placeStore,
  snackbarStore,
} from '@/store'
import PlaceCreateEditFields from '@/components/Place/CreateEditFields.vue'
import {
  Place,
} from '@/types/place'
import {
  Id,
} from '@/types/base'
import {
  newPlace,
} from '@/util/place'
import {
  form,
} from '@/mixins'
import omit from 'lodash.omit'

export default mixins(form)
  .extend({
    components: {
      PlaceCreateEditFields,
    },
    data: () => ({
      loadingSave: false,
      loadingDelete: false,
      editPlace: newPlace(),
    }),
    computed: {
      loadingGet (): boolean {
        return placeStore.isLoadingGet
      },
      hasChanged (): boolean {
        if (!this.place) return false
        const oldPlace = this.$copy(this.place)
        const editPlace = omit(oldPlace, [
          `id`,
        ])
        return !this.$isEqual<Place>(this.editPlace, editPlace)
      },
      place (): (Place & Id) | undefined {
        return placeStore.dataById(this.$route.params.id)
      },
    },
    watch: {
      place: {
        immediate: true,
        handler (place: (Place & Id)) {
          this.reset(place)
        },
      },
    },
    async created () {
      await placeStore.get(this.$route.params.id)
    },
    methods: {
      async save () {
        if (!this.validateForm()) return
        const editPlace: (Place & Id) = {
          id: this.$route.params.id,
          ...this.$copy(this.editPlace),
        }
        try {
          this.loadingSave = true
          await placeStore.update(editPlace)
          snackbarStore.addSiteMessage({
            type: `success`,
            snackMessage: `Place successfully updated`,
          })
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error updating place`,
          })
        }
        this.loadingSave = false
      },
      async deletePlace () {
        if (!confirm(`Are you sure you want to delete "${this.place?.name}"?`)) return
        try {
          this.loadingDelete = true
          await placeStore.delete(this.$route.params.id)
          snackbarStore.addSiteMessage({
            type: `success`,
            snackMessage: `Place successfully deleted`,
          })
          this.$router.go(-1)
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error deleting place`,
          })
        }
        this.loadingDelete = false
      },
      async reset (place: (Place & Id) | undefined) {
        if (!place) return
        const oldPlace = this.$copy(place)
        const editPlace = omit(oldPlace, [
          `id`,
        ])
        this.editPlace = editPlace
      },
    },
  })
